import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./ss22.module.scss"


const SpringSummer23 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "surreal-sandal-100-nappa-laminata-sandals",
      "surreal-mule-nappa-sandals",
      "futura-65-naplack-sandals",
      "futura-65-nappa-laminata-sandals",
      "orbit-65-nappa-crystals-sandals",
      "luster-nappa-crystals-sandals",
      "luster-nappa-laminata-crystals-sandals",
      "absolute-85-nappa-crystals-sandals",
      "absolute-40-nappa-laminata-crystals-sandals",
      "stellaire-pvc-crystals-sandals",
      "solar-100-naplack-sandals",
      "solar-100-nappa-laminata-sandals",
      "solar-65-naplack-sandals",
      "admirer-naplack-sandals",
      "admirer-nappa-laminata-sandals",
      "luster-40-nappa-sandals",
      "luster-40-nappa-laminata-sandals",
      "absolute-40-naplack-sandals",
      "absolute-40-nappa-laminata-sandals",
      "midnight-naplack-sandals",
      "reverse-sandal-75-suede-sandals",
      "reverse-wedge-90-suede-sandals",
      "reverse-pump-75-suede-pumps",
      "studio-54-75-suede-sandals",
      "stow-mule-10-naplack-flats",
      "stow-mule-10-nappa-laminata-flats",
      "stow-mule-10-nappa-flats",
      "stow-mule-10-suede-flats",
      "stow-sneaker-suede-sneakers",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <div className={styles.ss22}>
      <SEO
        title="Spring/Summer '23"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ss23_lp_desktop_1_8a2935cd-4125-4ec7-91db-9473033777c8_2000x.jpg?v=1677703204",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ss23_lp_mobile_1_cf2121f9-e964-4e52-961a-8bf25ccbe270_850x.jpg?v=1677703204",
          }}
          altText="Spring/Summer '23 Image"
          className={styles.imgFullWidth}
        />
        <Heading
          tag="h1"
          level="0"
          className={styles.headingDark}
        >
          Spring/Summer '23
        </Heading>
      </header>

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
          "surreal-sandal-100-nappa-laminata-sandals",
          "surreal-mule-nappa-sandals",
          "futura-65-naplack-sandals",
          "futura-65-nappa-laminata-sandals",
          "orbit-65-nappa-crystals-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss23`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ss23_lp_desktop_2_a4c24277-273c-45d2-8751-81d8d9078a8e_2000x.jpg?v=1677703204",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ss23_lp_mobile_2_b65e248c-6f59-4e9f-8708-4980bc804272_850x.jpg?v=1677703203",
        }}
        altText="Spring/Summer '23 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
          "luster-nappa-crystals-sandals",
          "luster-nappa-laminata-crystals-sandals",
          "absolute-85-nappa-crystals-sandals",
          "absolute-40-nappa-laminata-crystals-sandals",
          "stellaire-pvc-crystals-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss23`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ss23_lp_desktop_3_83b2d707-ce3f-4239-9065-b36898d71d2c_2000x.jpg?v=1677703204",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/ss23_lp_mobile_3_0199c083-40e9-49cd-89e6-177a7a4b32f8_850x.jpg?v=1677703204",
        }}
        altText="Spring/Summer '23 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
          "solar-100-naplack-sandals",
          "solar-100-nappa-laminata-sandals",
          "solar-65-naplack-sandals",
          "admirer-naplack-sandals",
          "admirer-nappa-laminata-sandals",
          "luster-40-nappa-sandals",
          "luster-40-nappa-laminata-sandals",
          "absolute-40-naplack-sandals",
          "absolute-40-nappa-laminata-sandals",
          "midnight-naplack-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss23`}
            />
          ))}
      </ul>

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
          "reverse-sandal-75-suede-sandals",
          "reverse-wedge-90-suede-sandals",
          "reverse-pump-75-suede-pumps",
          "studio-54-75-suede-sandals",
          "stow-mule-10-naplack-flats",
          "stow-mule-10-nappa-laminata-flats",
          "stow-mule-10-nappa-flats",
          "stow-mule-10-suede-flats",
          "stow-sneaker-suede-sneakers",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss23`}
            />
          ))}
      </ul>
    </div>
  )
}

export default SpringSummer23

export const query = graphql`
    query SpringSummer23Query {
        allProductStyle(filter: {handle: {in: [
            "surreal-sandal-100-nappa-laminata-sandals",
            "surreal-mule-nappa-sandals",
            "futura-65-naplack-sandals",
            "futura-65-nappa-laminata-sandals",
            "orbit-65-nappa-crystals-sandals",
            "luster-nappa-crystals-sandals",
            "luster-nappa-laminata-crystals-sandals",
            "absolute-85-nappa-crystals-sandals",
            "absolute-40-nappa-laminata-crystals-sandals",
            "stellaire-pvc-crystals-sandals",
            "solar-100-naplack-sandals",
            "solar-100-nappa-laminata-sandals",
            "solar-65-naplack-sandals",
            "admirer-naplack-sandals",
            "admirer-nappa-laminata-sandals",
            "luster-40-nappa-sandals",
            "luster-40-nappa-laminata-sandals",
            "absolute-40-naplack-sandals",
            "absolute-40-nappa-laminata-sandals",
            "midnight-naplack-sandals",
            "reverse-sandal-75-suede-sandals",
            "reverse-wedge-90-suede-sandals",
            "reverse-pump-75-suede-pumps",
            "studio-54-75-suede-sandals",
            "stow-mule-10-naplack-flats",
            "stow-mule-10-nappa-laminata-flats",
            "stow-mule-10-nappa-flats",
            "stow-mule-10-suede-flats",
            "stow-sneaker-suede-sneakers",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
